import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './emailForm.css';
import data from '../../utils.js/data.json';

const EmailForm = (props) => {

    /* ----- props ----- */
    const {
        step,
        setStep,
        preventiviMap,
        setPreventiviMap,
        setShowEmailForm,
        setShowModal,
        links,
        setLinks
    } = props;

    /* ----- useState() ----- */
    const [title, setTitle] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [termCond] = useState(data?.['term&Cond']);
    const [enableSendBtn, setEnableSendBtn] = useState(false);
    const [showAlert, setshowAlert] = useState(false);
    const [checkInput, setcheckInput] = useState(false);

    /* ----- useRef() ----- */
    const form = useRef();
    const ref_name = useRef();
    const email_ref = useRef();
    const msg_ref = useRef();

    /* ----- useEffect() ----- */
    useEffect(() => {

        window.scrollTo({
            top: document.querySelector("#emailForm-container")?.offsetTop,
            behavior: 'smooth'
        });

        /* ---------------------------- */

        const selectedService = preventiviMap?.find(servizio => servizio?.selected);
        setTitle(selectedService?.title);
        // eslint-disable-next-line
    }, []);

    const targetStep = () => {
        switch (step) {
            case 3:
                return '#ie-step2';
            default:
                break;
        }
    };

    const getBackToPrevStep = () => {
        const selectedService = preventiviMap?.find(servizio => servizio?.selected);
        if (selectedService?.title === "Antintrusione e Videosorveglianza") {
            const preventiviUpdated = preventiviMap?.map(servizio => ({ ...servizio, selected: false }));
            setPreventiviMap(preventiviUpdated);
            window.scrollTo({
                top: document.querySelector("#iconsChoice")?.offsetTop,
                behavior: 'smooth'
            });
        } else {
            window.scrollTo({
                top: document.querySelector(targetStep())?.offsetTop,
                behavior: 'smooth'
            });
        }

        setStep(step - 1);
        setShowEmailForm(false);
    };

    const selectedOption = () => {

        const selectedService = preventiviMap?.find(servizio => servizio?.selected);
        let choices, selectedOption, choices2;
        
        switch (selectedService?.title) {
            case "Impianti Elettrici":
                if (selectedService?.choice?.guasto?.selected) {
                    return "Impianti Elettrici - Guasto";
                } else {
                    choices = Object.entries(selectedService?.choice);
                    selectedOption = choices?.find(option => option?.[1]?.selected);
                    choices2 = Object.entries(selectedOption?.[1]?.choice);
                    const selectedOption2 = choices2?.find(option => option?.[1]?.selected);
                    const choice3 = Object.entries(selectedOption2?.[1]?.choice);
                    let selectedOption3 = [];
                    choice3?.forEach(option => { option?.find(elem => elem === true) && selectedOption3?.push(option) });
                    const stringEnd = selectedOption3?.[0]?.[0] === "withProject" ? "con progetto" : "no progetto";
                    return `Impianti Elettrici - Nuova Installazione - ${selectedOption2?.[0]} - ${stringEnd}`;
                }
            case "Fotovoltaico":
                choices = Object.entries(selectedService?.choice);
                if (choices?.[0]?.[1]?.selected) {
                    choices2 = Object.entries(choices?.[0]?.[1]?.choice);
                    const selectedOption2 = choices2?.find(choice => choice?.[1]?.selected);
                    if (selectedOption2?.[0] === "ampliamento") {
                        const choice3 = Object.entries(selectedOption2?.[1]?.choice);
                        const selectedOption3 = choice3?.find(option => option?.[1]?.selected);
                        return `Fotovoltaico - impianto già installato - ampliamento - ${selectedOption3?.[0]}`;
                    } else {
                        return `Fotovoltaico - Impianto già installato - ${selectedOption2?.[0]}`;
                    }
                } else {
                    choices2 = Object.entries(choices?.[1]?.[1]?.choice);
                    const selectedOption2 = choices2?.find(choice => choice?.[1]?.selected);
                    if (selectedOption2?.[0] === "tettoTerrazzo") {
                        const choice3 = Object.entries(selectedOption2?.[1]?.choice);
                        const selectedOption3 = choice3?.find(option => option?.[1]?.selected);
                        const choice4 = Object.entries(selectedOption3?.[1]?.choice);
                        const selectedOption4 = choice4?.find(option => option?.[1]);
                        const title3 = selectedOption4?.[0] === "withNoProject" ? "no progetto" : "con progetto";
                        return `Fotovoltaico - Nuova Installazione - Tetto Terrazzo - ${selectedOption3?.[0]} - ${title3}`;   
                    } else {
                        const choice3 = Object.entries(selectedOption2?.[1]?.choice);
                        const selectedOption3 = choice3?.find(option => option?.[1]?.selected);
                        if (selectedOption3?.[0] === "civile") {
                            const choice4 = Object.entries(selectedOption3?.[1]?.choice);
                            const selectedOption4 = choice4?.find(option => option?.[1]);
                            const title = selectedOption4?.[0] === "lineaVita" ? "linea vita" : "no linea vita";
                            return `Fotovoltaico - Nuova Installazione - Tetto a Falda - Civile - ${title}`;
                        } else {
                            const choice4 = Object.entries(selectedOption3?.[1]?.choice);
                            const selectedOption4 = choice4?.find(option => option?.[1]?.selected);
                            const title = selectedOption4?.[0] === "withProject" ? "con progetto" : "no progetto";
                            const choice5 = Object.entries(selectedOption4?.[1]?.choice);
                            const selectedOption5 = choice5?.find(option => option?.[1]);
                            const title2 = selectedOption5?.[0] === "lineaVita" ? "linea vita" : "no linea vita";
                            return `Fotovoltaico - Nuova Installazione - tetto a falda - Industriale - ${title} - ${title2}`;
                        }
                    }
                }
            case "Termoidraulico":
                choices = Object.entries(selectedService?.choice);
                selectedOption = choices?.find(option => option?.[1]?.selected);
                choices2 = Object.entries(selectedOption?.[1]?.choice);
                let selectedOption2 = [];
                choices2?.forEach(option => { option?.find(elem => elem === true) && selectedOption2?.push(option) });
                const stringEnd = selectedOption2?.[0]?.[0] === "withProject" ? "con progetto" : "no progetto";
                return `Termoidraulico - ${selectedOption?.[0]} - ${stringEnd}`;
            case "Antintrusione e Videosorveglianza":
                return "Antintrusione e Videosorveglianza";
            default:
                return "";
        };
    };

    const enableInviaBtn = () => {
        if (enableSendBtn && ref_name?.current?.value && email_ref?.current?.value && msg_ref?.current?.value) {
            return "none";
        } else {
            return "flex";
        }
    };

    const sendEmail = (e) => {

        const scrollTop  = window.pageYOffset;
        const scrollLeft = window.pageXOffset;

        window.onscroll = function() {
            window.scrollTo(scrollLeft, scrollTop);
        };

        e.preventDefault();

        const serviceId  = process.env.REACT_APP_SERVICE_ID;
        const templateId = process.env.REACT_APP_TEMPLATE_ID;
        const publicKey  = process.env.REACT_APP_PUBLIC_KEY;

        const params = {
            'from_name'        : name,
            'from_email'       : email,
            'to_name'          : "AC Sistems",
            'selected_service' : selectedOption(),
            'message'          : message
        };

        emailjs.send(serviceId, templateId, params, publicKey)

            .then(response => {
                console.log("Email sent successfully", response);
                setName("");
                setEmail("");
                setMessage("");
            })

            .catch(error => console.log("Error sending email", error));
    };

    const handleAlertMsg = () => {

        const inputValues = [ref_name?.current?.value, email_ref?.current?.value, msg_ref?.current?.value];
        const emptyInput = inputValues?.find(input => input === "");

        if (emptyInput === "") {
            return "compila prima i campi mancanti";
        } else if (!enableSendBtn) {
            return "acconsenti prima alla nostra Privacy Policy";
        }
    };

    const showAlertMsg = () => {
        setshowAlert(true);
        setcheckInput(true);
        handleAlertMsg();
    };

    const showThankYouModal = () => {
        setShowModal((prev) => ({ ...prev, show: true, type: "preventivo" }));
        const defaultLinks = links?.map(link => ({ ...link, hasFocused: false }));
        setLinks(defaultLinks);
    };

    return (
        <div id='emailForm-container' style={{ backgroundColor: step % 2 === 0 ? "white" : "#1F1F1F" }}>
            <div className={step % 2 === 0 ? 'imp_msg-container' :'imp_msg-container-three'}>
                <div className='didascalia'>
                    <h1>{step}.</h1>
                    <h3>{title}</h3>
                </div>
                <button
                    className={step % 2 === 0 ? 'getBackCircle1' : 'getBackCircle2'}
                    onClick={() => getBackToPrevStep()}
                >
                    torna indietro
                </button>
            </div>

            <div className='email-form-container'>

                <form
                    ref={form}
                    style={{ backgroundColor: step % 2 === 0 ? "#1f1f1fee" : "rgba(255, 255, 255, 0.957)" }}
                    onSubmit={sendEmail}>

                    <div className='nomeCognome'>
                        <label
                            style={{ color: step % 2 === 0 ? "whitesmoke" : "#1f1f1f" }}
                        >
                            nome e cognome:
                        </label>
                        <input
                            ref={ref_name}
                            style={{ border: (checkInput && ref_name?.current?.value === "") && "2.5px solid #ee6055" }}
                            type="text"
                            name="user_name"
                            onBlur={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div className='email'>
                        <label
                            style={{ color: step % 2 === 0 ? "whitesmoke" : "#1f1f1f" }}
                        >
                            email:
                        </label>
                        <input
                            ref={email_ref}
                            style={{ border: (checkInput && email_ref?.current?.value === "") && "2.5px solid #ee6055" }}
                            type="email"
                            name="user_email"
                            onBlur={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className='message'>
                        <div>
                            <label
                                style={{ color: step % 2 === 0 ? "whitesmoke" : "#1f1f1f" }}
                            >
                                messaggio:
                            </label>
                        </div>
                        <div>
                            <textarea
                                ref={msg_ref}
                                style={{ border: (checkInput && msg_ref?.current?.value === "") && "2.5px solid #ee6055" }}
                                name="message"
                                onBlur={(e) => setMessage(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='submit-container'>

                        <div className='alertMsg-container'>
                            {showAlert && <p className='alertMsg'>{handleAlertMsg()}</p>}
                        </div>
                        
                        <div className='relative-div'>

                            <input 
                                className='submit'
                                type="submit"
                                value="Invia"
                                onClick={() => showThankYouModal()}
                            />

                            <div
                                className='divToHide'
                                style={{ display: enableInviaBtn() }}
                                onClick={() => showAlertMsg()}
                            />

                        </div>
                    </div>
                    
                </form>

                <div
                    style={{ backgroundColor: step % 2 === 0 ? "#1f1f1fee" : "rgba(255, 255, 255, 0.957)" }}
                    className='term_cond'
                >
                    <h3 style={{ color: step % 2 === 0 ? "whitesmoke" : "#1f1f1f" }}>{termCond?.title}</h3>
                    <p
                        className='term-msg'
                        style={{ color: step % 2 === 0 ? "whitesmoke" : "#1f1f1f" }}
                    >
                        {termCond?.msg}
                    </p>
                    <div>
                        <input 
                            type="checkbox"
                            onChange={() => setEnableSendBtn(value => !value)}
                        />
                        <p style={{ color: step % 2 === 0 ? "whitesmoke" : "#1f1f1f" }}>Acconsento alla vostra</p>
                        <a 
                            href="https://www.iubenda.com/privacy-policy/97287222"
                            target="_blank"
                            className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
                            title="Privacy Policy"
                            rel="noreferrer"
                        >
                            Privacy Policy
                        </a>
                    </div>
                </div>

            </div> 
        </div>
    );
};

export default EmailForm;