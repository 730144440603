import React from 'react';
import './headImage.css';
import slogan from '../../../utils.js/imgs/headerImage/zindeximg.jpeg';

const HeadImage = () => {
    return (
        <div className='headImage'>
            <div className='slogan-container'>
                <img src={slogan} alt="slogan" />
            </div>
        </div>
    );
}

export default HeadImage;