import React from 'react';
import './logo.css';
import headerLogo from '../../utils.js/imgs/logos/yellow_logo.png';
import footerLogo from '../../utils.js/imgs/logos/white-logo.png';
import { Link } from 'react-router-dom';

const Logo = ({ header, links, setLinks }) => {

    const getSrcAndClass = () => {
        return {
            class: header ? "logo-header" : "logo-footer",
            src:   header ?  headerLogo   :  footerLogo
        };
    };

    const unselectPreventiviButton = () => {

        window.scrollTo(0, 0);

        const newLinks = links?.map(link => ({ ...link, hasFocused: false }));
        setLinks(newLinks);
    };

    return (
        <div className={getSrcAndClass().class}>
            <div onClick={() => unselectPreventiviButton()}>
                <Link to="/">
                    <img src={getSrcAndClass().src} alt="logo" />
                </Link>
            </div>
        </div>
    );
}

export default Logo;