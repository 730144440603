import React from 'react';
import './social.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { /* faLinkedin, */ faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import data from '../../utils.js/data.json';

const Social = () => {

    const goToSocialProfile = (i) => {
        window.open(data?.social?.[i]?.url, "_blank");
    };

    return (
        <div className='icone'>

            <div>
                <h1>SOCIAL</h1>
            </div>

            <div className='icon-section'>
                <FontAwesomeIcon icon={faFacebook}  className='facebook-icon'  onClick={() => goToSocialProfile(0)} />
                <FontAwesomeIcon icon={faInstagram} className='instagram-icon' onClick={() => goToSocialProfile(1)} />
                {/* <FontAwesomeIcon icon={faLinkedin}  className='linkedIn-icon'  onClick={() => goToSocialProfile(2)} /> */}
            </div>
        </div>
    );
}

export default Social;