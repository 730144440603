import React, { useState } from 'react';
import './style/app.css';
import { Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Homepage from './components/home/homepage/Homepage';
import Footer from './components/footer/Footer';
import data from './utils.js/data.json';
import Preventivi from './pages/preventivi/Preventivi';
import Modal from './components/modal/Modal';
import GlobalStyle from './style/GlobalStyle';

const NavigationHandler = () => {

  /* ------ useState() ------ */
  const [links, setLinks] = useState(data.links);
  const [showModal, setShowModal] = useState({ show: false, type: "", id: null });
  const [isUserOnPreventivi, setIsUserOnPreventivi] = useState(false);

  return (
    <GlobalStyle>

      <Header
        links={links} setLinks={setLinks}
        setShowModal={setShowModal}
        isUserOnPreventivi={isUserOnPreventivi}
      />

      <Routes>

        <Route 
          path="/" exact 
          element={
            <Homepage
              links={links} setLinks={setLinks}
              setShowModal={setShowModal}
              setIsUserOnPreventivi={setIsUserOnPreventivi}
            />
          }
        />

        <Route 
          path="/preventivi" exact
          element={
            <Preventivi
              setShowModal={setShowModal}
              links={links} setLinks={setLinks}
              setIsUserOnPreventivi={setIsUserOnPreventivi}
            />
          }
        />
        
      </Routes>

      <Footer links={links} setLinks={setLinks} />

      {showModal?.show && (
        <Modal
            showModal={showModal}
            setShowModal={setShowModal}
        />
      )}

    </GlobalStyle>
  );
}

export default NavigationHandler;