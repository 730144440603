import React, { useEffect } from 'react';
import './homepage.css';
import ChiSiamo from '../chiSiamo/ChiSiamo';
import Servizi from '../servizi/Servizi';
import HeadImage from '../headImage/HeadImage';

const Homepage = (props) => {

    /* ---- props ---- */
    const {
        links,
        setLinks,
        setShowModal,
        setIsUserOnPreventivi
    } = props;

    /* ------ useEffect() ------ */
    useEffect(() => {
        scrollToSection();
        window.scrollTo(0, 0);
        setIsUserOnPreventivi(false);
        // eslint-disable-next-line
    }, []);

    const scrollToSection = () => {

        const focusedBtn = links?.find(link => link?.hasFocused);
    
        if (focusedBtn?.section !== "PREVENTIVI") {
            window.scrollTo({
                top: document.querySelector(focusedBtn?.target)?.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="homepage">

            <HeadImage />

            <Servizi
                links={links} setLinks={setLinks}
                setShowModal={setShowModal}
            />

            <ChiSiamo
                links={links} setLinks={setLinks}
            />
            
        </div>
    );
}

export default Homepage;