import React, { useEffect, useState } from 'react';
import './preventivi.css';
import data from '../../utils.js/data.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlugCircleBolt, faSolarPanel, faFan, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import ImpiantiElettrici from '../../components/serpentines/impiantiElettrici/ImpiantiElettrici';
import EmailForm from '../../components/emailForm/EmailForm';
import TermoIdraulico from '../../components/serpentines/termoidraulico/TermoIdraulico';
import Fotovoltaico from '../../components/serpentines/fotovoltaico/Fotovoltaico';

const Preventivi = (props) => {

    /* ------ props ------ */
    const {
        setShowModal,
        links,
        setLinks,
        setIsUserOnPreventivi
    } = props;

    /* ------ useState() ------ */
    const [preventiviMap, setPreventiviMap] = useState(data?.preventivi);
    const [icons] = useState([faPlugCircleBolt, faSolarPanel, faFan, faDoorOpen]);
    const [step, setStep] = useState(1);
    const [showEmailForm, setShowEmailForm] = useState(false);

    /* ------ useEffect() ------ */
    useEffect(() => {
        const newPreventiviMap = preventiviMap?.map((servizio, index) => ({ ...servizio, "icon": icons?.[index] }));
        setPreventiviMap(newPreventiviMap);
        setIsUserOnPreventivi(true);
        // eslint-disable-next-line
    }, []);

    const goToStepTwo = (id) => {

        //event.preventDefault();
        window.scrollTo({
            top: document?.querySelector("#ie-step25")?.offsetTop,
            behavior: 'smooth'
        });

        if (step === 1) {
            const updatedPreventivi = preventiviMap?.map((servizio, index) => {
                if (id === index) {
                    return { ...servizio, selected: true };
                } else {
                    return { ...servizio, selected: false };
                }
            });
            setPreventiviMap(updatedPreventivi);
            setStep(step + 1);
            id === 3 && setShowEmailForm(true);
        }
    };

    const startSerpentine = () => {

        const selectedService = preventiviMap?.find(servizio => servizio?.selected);
        const servizio = selectedService?.title;

        switch (servizio) {
            case "Impianti Elettrici":
                return <ImpiantiElettrici
                            step={step} setStep={setStep} setShowModal={setShowModal}
                            showEmailForm={showEmailForm} setShowEmailForm={setShowEmailForm}
                            preventiviMap={preventiviMap} setPreventiviMap={setPreventiviMap}
                            links={links} setLinks={setLinks}
                        />
            case "Fotovoltaico":
                return <Fotovoltaico
                            step={step} setStep={setStep} setShowModal={setShowModal}
                            showEmailForm={showEmailForm} setShowEmailForm={setShowEmailForm}
                            preventiviMap={preventiviMap} setPreventiviMap={setPreventiviMap}
                            links={links} setLinks={setLinks}
                        />
            case "Termoidraulico":
                return <TermoIdraulico
                            step={step} setStep={setStep} setShowModal={setShowModal}
                            showEmailForm={showEmailForm} setShowEmailForm={setShowEmailForm}
                            preventiviMap={preventiviMap} setPreventiviMap={setPreventiviMap}
                            links={links} setLinks={setLinks}
                        />
            case "Antintrusione e Videosorveglianza":
                if (showEmailForm) {
                    return <EmailForm
                                step={step} setStep={setStep} setShowEmailForm={setShowEmailForm}
                                preventiviMap={preventiviMap} setPreventiviMap={setPreventiviMap}
                                links={links} setLinks={setLinks} setShowModal={setShowModal}
                            />   
                }
                // eslint-disable-next-line
            default:
                return null;
        }
    };

    return (
        <div className='preventivi-page'>

            <div className='title-section'>
                <h1>PREVENTIVI</h1>
            </div>

            <div id='iconsChoice'>
                <div className='msg-container'>
                    <h1>1.</h1>
                    <h3>Ciao, di quale servizio hai bisogno ?</h3>
                </div>
                <div className='icons_container'>
                    {preventiviMap?.map((servizio, index) => (
                        <div key={index} className={!servizio?.selected ? 'icon-container' : 'icon-container_selected'} >
                            <div
                                style={{ cursor: step === 1 ? "pointer" : "auto" }}
                                onClick={() => goToStepTwo(index)}
                            >
                                <FontAwesomeIcon icon={servizio?.icon} />
                            </div>
                            <div>
                                <button
                                    className={(step > 1 && !servizio?.selected) ? 'disabled_btns' : 'btns'}
                                    disabled={step > 1} onClick={() => goToStepTwo(index)}
                                >
                                    {servizio?.title}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {startSerpentine()}
            
        </div>
    );
}

export default Preventivi;