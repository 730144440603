import React, { useEffect, useState } from 'react';
import './termoidraulico.css';
import EmailForm from '../../emailForm/EmailForm';

const TermoIdraulico = (props) => {

    /* ------ props ------ */
    const {
        step,
        setStep,
        showEmailForm,
        setShowEmailForm,
        preventiviMap,
        setPreventiviMap,
        setShowModal,
        links,
        setLinks
    } = props;

    /* ------ useState() ------ */
    const [showStepTwo  , setShowStepTwo]   = useState(true) ;
    const [showStepThree, setShowStepThree] = useState(false);

    /* ------ useeffect() ------ */
    useEffect(() => {
        
    }, []);

    const getBackToPrevStep = (stepNum) => {
        switch (stepNum) {
            case 2:
                const restartingPreventivi = preventiviMap?.map(servizio => ({ ...servizio, selected: false }));
                setPreventiviMap(restartingPreventivi);
                setShowStepTwo(false);
                window.scrollTo({
                    top: document.querySelector("#iconsChoice")?.offsetTop,
                    behavior: 'smooth'
                });
                break;
            case 3:
                setShowStepThree(false);
                window.scrollTo({
                    top: document.querySelector("#ie-step2")?.offsetTop,
                    behavior: 'smooth'
                });
                break;
            default:
                break;
        }
        setStep(step - 1);
    };

    const goToStepThree = (option) => {
        const updatedPreventivi = preventiviMap?.map(servizio => {
            if (servizio?.selected) {
                return {
                    ...servizio,
                    choice: {
                        civile      : {
                            ...servizio?.choice?.civile,
                            selected: option === 0 ? true : false
                        },
                        industriale : {
                            ...servizio?.choice?.industriale,
                            selected: option === 1 ? true : false
                        }
                    }
                }
            } else {
                return servizio;
            }
        });
        setPreventiviMap(updatedPreventivi);
        setStep(step + 1);
        setShowStepThree(true);
    };

    const goToStepFour  = (option) => {
        const updatedPreventivi = preventiviMap?.map(servizio => {
            if (servizio?.selected) {
                if (servizio?.choice?.civile?.selected) {
                    return {
                        ...servizio,
                        choice: {
                            ...servizio?.choice,
                            civile: {
                                ...servizio?.choice?.civile,
                                choice: {
                                    withProject   : option === 0 ? true : false,
                                    withNoProject : option === 1 ? true : false
                                }
                            }
                        }
                    }
                } else {
                    return {
                        ...servizio,
                        choice: {
                            ...servizio?.choice,
                            industriale: {
                                ...servizio?.choice?.industriale,
                                choice: {
                                    withProject   : option === 0 ? true : false,
                                    withNoProject : option === 1 ? true : false
                                }
                            }
                        }
                    }
                }
            } else {
                return servizio;
            }
        });
        setPreventiviMap(updatedPreventivi);
        setStep(step + 1);
        setShowEmailForm(true);
    };

    // ----- Steps della Serpentina ----- //
    const StepTwo = () => {

        const buttons = ["civile", "industriale"];

        return (
            <div className='imp_step_two' id='ie-step25'>
                <div className='imp_msg-container'>
                    <div className='didascalia'>
                        <h1>2.</h1>
                        <h3>Termoidraulico</h3>
                    </div>
                    {step === 2 && (
                        <button className='getBackCircle1' onClick={() => getBackToPrevStep(2)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div className='imp_btns-container'>
                    {buttons?.map((option, index) => (
                        <button
                            key={index}
                            className={step > 2 ? 'disabled-btn_even' : 'enabled-btn_even'}
                            disabled={step > 2} onClick={() => goToStepThree(index)}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
        );
    };
    const StepThree = () => {

        useEffect(() => {
            window.scrollTo({
                top: document.querySelector("#imp_step-three")?.offsetTop,
                behavior: 'smooth'
            });
        }, []);

        const buttons = ["ho un progetto", "non ho un progetto"];

        return (
            <div id='imp_step-three'>
                <div className='imp_msg-container-three'>
                    <div className='didascalia'>
                        <h1>3.</h1>
                        <h3>Termoidraulico</h3>
                    </div>
                    {step === 3 && (
                        <button className='getBackCircle2' onClick={() => getBackToPrevStep(3)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div className='imp_btns-container-three'>
                    {buttons?.map((option, index) => (
                        <button
                            className={step > 3 ? 'disabled-btn_odd' : 'enabled-btn_odd'}
                            onClick={() => goToStepFour(index)} disabled={step > 3}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div id='termoidraulico-page'>

            {showStepTwo   && <StepTwo   />}
            {showStepThree && <StepThree />}
            
            {showEmailForm && (
                <EmailForm
                    step={step} setStep={setStep}
                    preventiviMap={preventiviMap}
                    setShowEmailForm={setShowEmailForm}
                    setShowModal={setShowModal}
                    links={links} setLinks={setLinks}
                />
            )}
        </div>
    );
}

export default TermoIdraulico;