import React from 'react';
import './arrowUpward.css';

const ArrowUpward = ({ white, links, setLinks }) => {

    const getStyle = () => {
        return { color: white ? "whitesmoke" : "#a8a8a8" };
    };

    const scrollToTheTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const setAllLinksOnFalse = () => {
        const newLinks = links?.map(link => ( { ...link, hasFocused: false } ));
        setLinks(newLinks);
    };

    const handleOnClick = () => {
        scrollToTheTop();
        setAllLinksOnFalse();
    };

    return (
        <span className="material-icons freccia" style={getStyle()} onClick={() => handleOnClick()}>
            arrow_upward
        </span>
    );
}

export default ArrowUpward;