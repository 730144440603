import React, { useEffect, useState } from 'react';
import './fotovoltaico.css';
import EmailForm from '../../emailForm/EmailForm';

const Fotovoltaico = (props) => {

    /* ------ props ------ */
    const {
        step,
        setStep,
        showEmailForm,
        setShowEmailForm,
        preventiviMap,
        setPreventiviMap,
        setShowModal,
        links,
        setLinks
    } = props;

    /* ------ useState() ------ */
    const [showStepTwo  , setShowStepTwo  ]  = useState(true) ;
    const [showStepThree, setShowStepThree]  = useState(false);
    const [showStepFour , setShowStepFour ]  = useState(false);
    const [showStepFive , setShowStepFive ]  = useState(false);
    const [showStepSix  , setShowStepSix  ]  = useState(false);

    /* ------ useEffect ----- */
    useEffect(() => {
        window.scrollTo({
            top: document.querySelector("#fotovoltaico-page")?.offsetTop,
            behavior: 'smooth'
        });
    }, []);

    const getBackToPrevStep = (stepNum) => {
        switch (stepNum) {
            case 2:
                const restartingPreventivi = preventiviMap?.map(servizio => ({ ...servizio, selected: false }));
                setPreventiviMap(restartingPreventivi);
                setShowStepTwo(false);
                window.scrollTo({
                    top: document.querySelector("#iconsChoice")?.offsetTop,
                    behavior: 'smooth'
                });
                break;
            case 3:
                window.scrollTo({
                    top: document.querySelector("#ie-step2")?.offsetTop,
                    behavior: 'smooth'
                });
                setShowStepThree(false);
                break;
            case 4:
                setShowStepFour(false);
                break;
            case 5:
                setShowStepFive(false);
                break;
            case 6:
                setShowStepSix(false);
                break;
            default:
                break;
        }
        setStep(step - 1);
    };

    const goToStepThree = (option) => {

        const updatedPreventivi = preventiviMap?.map(servizio => {
            if (servizio?.selected) {
                return {
                    ...servizio,
                    choice: {
                        impGiaInstallato   : {
                            ...servizio?.choice?.impGiaInstallato,
                            selected: option === 0 ? true : false
                        },
                        nuovaInstallazione : {
                            ...servizio?.choice?.nuovaInstallazione,
                            selected: option === 1 ? true : false
                        }
                    }
                }
            } else {
                return servizio;
            }
        });

        setPreventiviMap(updatedPreventivi);
        setStep(step + 1);
        setShowStepThree(true);
    };

    const goToStepFour = (buttons, option) => {

        const updatedPreventiMap = preventiviMap?.map(servizio => {
            if (servizio?.selected && buttons?.length === 4) {
                return {
                    ...servizio,
                    choice: {
                        ...servizio?.choice,
                        impGiaInstallato   : {
                            ...servizio?.choice?.impGiaInstallato,
                            choice: {
                                guasto      : { selected: option === 0 ? true : false },
                                ampliamento : {
                                    ...servizio?.choice?.impGiaInstallato?.choice?.ampliamento,
                                    selected: option === 1 ? true : false
                                },
                                lavaggio    : { selected: option === 2 ? true : false },
                                altro       : { selected: option === 3 ? true : false }
                            }
                        }
                    }
                };
            } else if (servizio?.selected && buttons?.length === 2) {
                return {
                    ...servizio,
                    choice: {
                        ...servizio?.choice,
                        nuovaInstallazione : {
                            ...servizio?.choice?.nuovaInstallazione,
                            choice: {
                                tettoTerrazzo : {
                                    ...servizio?.choice?.nuovaInstallazione?.choice?.tettoTerrazzo,
                                    selected: option === 0 ? true : false
                                },
                                tettoAfalda   : {
                                    ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda,
                                    selected: option === 1 ? true : false
                                } 
                            }
                        }
                    }
                };
            } else {
                return servizio;
            }
        });
        setPreventiviMap(updatedPreventiMap);

        setStep(step + 1);
        if (buttons?.length === 4) {
            if (option === 1) {
                setShowStepFour(true);
            } else {
                setShowEmailForm(true);
            }
        } else {
            setShowStepFour(true);
        }
    };

    const goToStepFive = (buttons, option) => {

        let updatedPreventivi = [];

        if (buttons?.[0] === "pannelli") {
            updatedPreventivi = preventiviMap?.map(servizio => {
                if (servizio?.selected) {
                    return {
                        ...servizio,
                        choice: {
                            ...servizio?.choice,
                            impGiaInstallato   : {
                                ...servizio?.choice?.impGiaInstallato,
                                choice: {
                                    ...servizio?.choice?.impGiaInstallato?.choice,
                                    ampliamento: {
                                        ...servizio?.choice?.impGiaInstallato?.choice?.ampliamento,
                                        choice: {
                                            pannelli: { selected: option === 0 ? true : false },
                                            batterie: { selected: option === 1 ? true : false }
                                        }
                                    }
                                }
                            }
                        }
                    };
                } else {
                    return servizio;
                }
            });
            setPreventiviMap(updatedPreventivi);
            setStep(step + 1);
            setShowEmailForm(true);
        } else {
            updatedPreventivi = preventiviMap?.map(servizio => {
                if (servizio?.selected) {
                    return {
                        ...servizio,
                        choice: {
                            ...servizio?.choice,
                            nuovaInstallazione: {
                                ...servizio?.choice?.nuovaInstallazione,
                                choice: {
                                    tettoTerrazzo : {
                                        ...servizio?.choice?.nuovaInstallazione?.choice?.tettoTerrazzo,
                                        choice: {
                                            civile      : {
                                                ...servizio?.choice?.nuovaInstallazione?.choice?.tettoTerrazzo?.choice?.civile,
                                                selected: option === 1 ? true : false
                                            },
                                            industriale : {
                                                ...servizio?.choice?.nuovaInstallazione?.choice?.tettoTerrazzo?.choice?.industriale,
                                                selected: option === 0 ? true : false
                                            }
                                        }
                                    },
                                    tettoAfalda   : {
                                        ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda,
                                        choice: {
                                            civile      : {
                                                ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice?.civile,
                                                selected: option === 1 ? true : false
                                            },
                                            industriale : {
                                                ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice?.industriale,
                                                selected: option === 0 ? true : false
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    };
                } else {
                    return servizio;
                }
            });
            setPreventiviMap(updatedPreventivi);
            setStep(step + 1);
            setShowStepFive(true);
        }
    };

    const goToStepSix = (choice, option) => {

        let updatedPreventivi = [];

        if ((choice?.[2] === "tetto terrazzo") || (choice?.[2] === "tetto a falda" && choice?.[3] === "civile")) {
            updatedPreventivi = preventiviMap?.map(servizio => {
                if (servizio?.selected) {
                    return {
                        ...servizio,
                        choice: {
                            ...servizio?.choice,
                            nuovaInstallazione: {
                                ...servizio?.choice?.nuovaInstallazione,
                                choice: {
                                    tettoTerrazzo : {
                                        ...servizio?.choice?.nuovaInstallazione?.choice?.tettoTerrazzo,
                                        choice: {
                                            ...servizio?.choice?.nuovaInstallazione?.choice?.tettoTerrazzo?.choice,
                                            civile      : {
                                                ...servizio?.choice?.nuovaInstallazione?.choice?.tettoTerrazzo?.choice?.civile,
                                                choice : {
                                                    withProject   : option === 0 ? true : false,
                                                    withNoProject : option === 1 ? true : false
                                                }
                                            },
                                            industriale : {
                                                ...servizio?.choice?.nuovaInstallazione?.choice?.tettoTerrazzo?.choice?.industriale,
                                                choice : {
                                                    withProject   : option === 0 ? true : false,
                                                    withNoProject : option === 1 ? true : false
                                                }
                                            }
                                        }
                                    },
                                    tettoAfalda   : {
                                        ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda,
                                        choice: {
                                            civile      : {
                                                ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice?.civile,
                                                choice: {
                                                    lineaVita   : option === 0 ? true : false,
                                                    noLineaVita : option === 1 ? true : false
                                                }
                                            }
                                        }
                                    }
                                } 
                            }
                        }
                    };
                } else {
                    return servizio;
                }
            });
            setPreventiviMap(updatedPreventivi);
            setStep(step + 1);
            setShowEmailForm(true);
        } else {
            updatedPreventivi = preventiviMap?.map(servizio => {
                if (servizio?.selected) {
                    return {
                        ...servizio,
                        choice: {
                            ...servizio?.choice,
                            nuovaInstallazione: {
                                ...servizio?.choice?.nuovaInstallazione,
                                choice: {
                                    ...servizio?.choice?.nuovaInstallazione?.choice,
                                    tettoAfalda: {
                                        ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda,
                                        choice: {
                                            ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice,
                                            industriale: {
                                                ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice?.industriale,
                                                choice: {
                                                    withProject   : {
                                                        ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice?.industriale?.choice?.withProject,
                                                        selected: option === 0 ? true : false
                                                    },
                                                    withNoProject : {
                                                        ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice?.industriale?.choice?.withNoProject,
                                                        selected: option === 1 ? true : false
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    };
                } else {
                    return servizio;
                }
            })
            setPreventiviMap(updatedPreventivi);
            setStep(step + 1);
            setShowStepSix(true);
        }
    };

    const goToStepSeven = (choice, option) => {

        const condition = () => {
            return choice?.[4] === "progetto";
        };

        const updatedPreventivi = preventiviMap?.map(servizio => {
            if (servizio?.selected) {
                return {
                    ...servizio,
                    choice: {
                        ...servizio?.choice,
                        nuovaInstallazione: {
                            ...servizio?.choice?.nuovaInstallazione,
                            choice: {
                                ...servizio?.choice?.nuovaInstallazione?.choice,
                                tettoAfalda: {
                                    ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda,
                                    choice: {
                                        ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice,
                                        industriale: {
                                            ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice?.industriale,
                                            choice: {
                                                withProject   : {
                                                    ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice?.industriale?.choice?.withProject,
                                                    choice : {
                                                        lineaVita   : (condition() && option === 0) ? true : false,
                                                        noLineaVita : (condition() && option === 1) ? true : false
                                                    }
                                                },
                                                withNoProject : {
                                                    ...servizio?.choice?.nuovaInstallazione?.choice?.tettoAfalda?.choice?.industriale?.choice?.withNoProject,
                                                    choice : {
                                                        lineaVita   : (!condition() && option === 0) ? true : false,
                                                        noLineaVita : (!condition() && option === 1) ? true : false
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                return servizio;
            }
        });
        setPreventiviMap(updatedPreventivi);

        setStep(step + 1);
        setShowEmailForm(true);
    };

    // ----- Steps della Serpentina ----- //
    const StepTwo = () => {

        const buttons = ["impianto già installato", "nuova installazione"];

        return (
            <div className='imp_step_two' id='ie-step2'>
                <div className='imp_msg-container'>
                    <div className='didascalia'>
                        <h1>2.</h1>
                        <h3>Fotovoltaico</h3>
                    </div>
                    
                    {step === 2 && (
                        <button className='getBackCircle1' onClick={() => getBackToPrevStep(2)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div className='imp_btns-container'>
                    {buttons?.map((option, index) => (
                        <button
                            key={index}
                            className={step > 2 ? 'disabled-btn_even' : 'enabled-btn_even'}
                            disabled={step > 2} onClick={() => goToStepThree(index)}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
        );
    };
    const StepThree = () => {

        useEffect(() => {
            window.scrollTo({
                top: document.querySelector("#imp_step-three")?.offsetTop,
                behavior: 'smooth'
            });
        }, []);

        const generateStepTitle = () => {
            const selectedService = preventiviMap?.find(service => service?.selected);
            const choices = Object.entries(selectedService?.choice);
            const selectedOption = choices?.find(option => option?.[1]?.selected);
            if (selectedOption?.[0] === "impGiaInstallato") {
                return "Fotovoltaico - Imp. già installato";
            } else {
                return "Fotovoltaico - Nuova Installazione";
            }
        };

        const choice = generateStepTitle().split(" - ");
        const buttons = (choice?.[1] === "Imp. già installato") ? (
            ["guasto", "ampliamento", "lavaggio", "altro"]
        ) : (
            ["tetto terrazzo", "tetto a falda"]
        );

        const handlePadding = () => {
            if (choice?.[1] === "Imp. già installato") {
                return { padding: "0 12% 0 12%" };
            } else {
                return { padding: "0 31% 0 31%" };
            }
        };

        return (
            <div id='imp_step-three'>
                <div className='imp_msg-container-three'>
                    <div className='didascalia'>
                        <h1>3.</h1>
                        <h3>Fotovoltaico</h3>
                    </div>
                    {step === 3 && (
                        <button className='getBackCircle2' onClick={() => getBackToPrevStep(3)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div
                    className={choice?.[1] === "Imp. già installato" ? "options" : "imp_btns-container"}
                    style={handlePadding()}
                >
                    {buttons?.map((choice, index) => (
                        <button
                            key={index}
                            className={step > 3 ? 'disabled-btn_odd' : 'enabled-btn_odd'}
                            onClick={() => goToStepFour(buttons, index)} disabled={step > 3}
                        >
                            {choice}
                        </button>
                    ))}
                </div>
            </div>
        );
    };
    const StepFour = () => {

        useEffect(() => {
            window.scrollTo({
                top: document.querySelector("#imp_step-four")?.offsetTop,
                behavior: 'smooth'
            });
        }, []);

        const generateStepTitle = () => {
            const selectedService = preventiviMap?.find(service => service?.selected);
            const choices = Object.entries(selectedService?.choice);
            const selectedOption = choices?.find(option => option?.[1]?.selected);
            const title = selectedOption?.[0] === "impGiaInstallato" ? "imp. già installato" : "nuova installazione";
            const choices2 = Object.entries(selectedOption?.[1]?.choice);
            const selectedOption2 = choices2?.find(option => option?.[1]?.selected);
            let title2;
            switch (selectedOption2?.[0]) {
                case "ampliamento":
                    title2 = "ampliamento";
                    break
                case "tettoTerrazzo":
                    title2 = "tetto terrazzo";
                    break
                case "tettoAfalda":
                    title2 = "tetto a falda";
                    break
                default:
                    return null;
            }
            return `Fotovoltaico - ${title} - ${title2}`;
        };

        const choice = generateStepTitle()?.split(" - ");
        const buttons = (choice?.[2] === "ampliamento") ? (
            ["pannelli", "batterie"]
        ) : (
            ["industriale", "civile"]
        );

        return (
            <div id='imp_step-four'>
                <div className='imp_msg-container'>
                    <div className='didascalia'>
                        <h1>4.</h1>
                        <h3>Fotovoltaico</h3>
                    </div>
                    {step === 4 && (
                        <button className='getBackCircle1' onClick={() => getBackToPrevStep(4)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div className='imp_btns-container'>
                    {buttons?.map((option, index) => (
                        <button
                            key={index}
                            className={step > 4 ? 'disabled-btn_even' : 'enabled-btn_even'}
                            onClick={() => goToStepFive(buttons, index)} disabled={step > 4}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
        );
    };
    const StepFive = () => {

        useEffect(() => {
            window.scrollTo({
                top: document.querySelector("#imp_step-five")?.offsetTop,
                behavior: 'smooth'
            });
        }, []);

        const generateStepTitle = () => {
            const selectedService = preventiviMap?.find(service => service?.selected);
            const choices = Object.entries(selectedService?.choice);
            const selectedOption = choices?.find(option => option?.[1]?.selected);
            const choices2 = Object.entries(selectedOption?.[1]?.choice);
            const selectedOption2 = choices2?.find(option => option?.[1]?.selected);
            const title = selectedOption2?.[0] === "tettoAfalda" ? "tetto a falda" : "tetto terrazzo";
            const choices3 = Object.entries(selectedOption2?.[1]?.choice);
            const selectedOption3 = choices3?.find(option => option?.[1]?.selected);
            return `Fotovoltaico - Nuova Installazione - ${title} - ${selectedOption3?.[0]}`;
        };

        const choice = generateStepTitle()?.split(" - ");
        const buttons = (choice?.[2] === "tetto a falda" && choice?.[3] === "civile") ? (
            ["ho una linea vita", "non ho una linea vita"]
        ) : (
            ["ho un progetto", "non ho un progetto"]
        );

        const splitValue = (string, id) => {
            if (buttons?.[0] === "ho una linea vita") {
                if (id === 1) {
                    const wordsArray = string?.split(" ");
                    const word1 = [`${wordsArray[0]} `, `${wordsArray[1]} `, `${wordsArray[2]} `];
                    const word2 = [`${wordsArray[3]} `, wordsArray[4]];
                    return <>{word1} <br/> {word2}</>;
                } else {
                    const wordsArray = string?.split(" ");
                    const word1 = [`${wordsArray[0]} `, `${wordsArray[1]} `];
                    const word2 = [`${wordsArray[2]} `, wordsArray[3]];
                    return <>{word1} <br/> {word2}</>;
                }
            } else {
                return string;
            }
        };
        
        return (
            <div id='imp_step-five'>
                <div className='imp_msg-container-three'>
                    <div className='didascalia'>
                        <h1>5.</h1>
                        <h3>Fotovoltaico</h3>
                    </div>
                    {step === 5 && (
                        <button className='getBackCircle2' onClick={() => getBackToPrevStep(5)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div className='imp_btns-container-three'>
                    {buttons?.map((option, index) => (
                        <button
                            key={index}
                            className={step > 5 ? 'disabled-btn_odd' : 'enabled-btn_odd'}
                            onClick={() => goToStepSix(choice, index)} disabled={step > 5}
                        >
                            {splitValue(option, index)}
                        </button>
                    ))}
                </div>
            </div>
        );
    };
    const StepSix = () => {

        useEffect(() => {
            window.scrollTo({
                top: document.querySelector("#imp_step-six")?.offsetTop,
                behavior: 'smooth'
            });
        }, []);

        const generateStepTitle = () => {
            const selectedService = preventiviMap?.find(service => service?.selected);
            const choices = Object.entries(selectedService?.choice);
            const selectedOption = choices?.find(option => option?.[1]?.selected);
            const choices2 = Object.entries(selectedOption?.[1]?.choice);
            const selectedOption2 = choices2?.find(option => option?.[1]?.selected);
            const title = selectedOption2?.[0] === "tettoAfalda" ? "tetto a falda" : "tetto terrazzo";
            const choices3 = Object.entries(selectedOption2?.[1]?.choice);
            const selectedOption3 = choices3?.find(option => option?.[1]?.selected);
            const choices4 = Object.entries(selectedOption3?.[1]?.choice);
            const selectedOption4 = choices4?.find(option => option?.[1]?.selected);
            const title2 = selectedOption4?.[0] === "withNoProject" ? "no progetto" : "progetto";
            return `Fotovoltaico - Nuova Inst. - ${title} - ${selectedOption3?.[0]} - ${title2}`;
        };

        const choice = generateStepTitle()?.split(" - ");
        const buttons = ["ho una linea vita", "non ho una linea vita"];

        const splitValue = (string, id) => {
            if (id === 1) {
                const wordsArray = string?.split(" ");
                const word1 = [`${wordsArray[0]} `, `${wordsArray[1]} `, `${wordsArray[2]} `];
                const word2 = [`${wordsArray[3]} `, wordsArray[4]];
                return <>{word1} <br/> {word2}</>;
            } else {
                const wordsArray = string?.split(" ");
                const word1 = [`${wordsArray[0]} `, `${wordsArray[1]} `];
                const word2 = [`${wordsArray[2]} `, wordsArray[3]];
                return <>{word1} <br/> {word2}</>;            }
        };

        return (
            <div id='imp_step-six'>
                <div className='imp_msg-container'>
                    <div className='didascalia'>
                        <h1>6.</h1>
                        <h3>Fotovoltaico</h3>
                    </div>
                    {step === 6 && (
                        <button className='getBackCircle1' onClick={() => getBackToPrevStep(6)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div className='imp_btns-container'>
                    {buttons?.map((option, index) => (
                        <button
                            key={index}
                            className={step > 6 ? 'disabled-btn_even' : 'enabled-btn_even'}
                            onClick={() => goToStepSeven(choice, index)} disabled={step > 6}
                        >
                            {splitValue(option, index)}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div id='fotovoltaico-page'>
            {showStepTwo   && <StepTwo   />}
            {showStepThree && <StepThree />}
            {showStepFour  && <StepFour  />}
            {showStepFive  && <StepFive  />}
            {showStepSix   && <StepSix   />}

            {showEmailForm && (
                <EmailForm
                    step={step} setStep={setStep}
                    preventiviMap={preventiviMap}
                    setShowEmailForm={setShowEmailForm}
                    setShowModal={setShowModal}
                    links={links} setLinks={setLinks}
                />
            )}
        </div>
    );
}

export default Fotovoltaico;