import React, { useState } from 'react';
import './header.css';
import Logo from '../logo/Logo';
import { Link } from "react-router-dom";
import HamburgerMenu from '../hamburger/HamburgerMenu';

const Header = (props) => {

    /* ---- props ---- */
    const {
        links,
        setLinks,
        setShowModal,
        isUserOnPreventivi
    } = props;

    /* ---- useState() ---- */
    const [mobileMenu, setMobileMenu] = useState(false);

    const stopScroll = () => {
        const scrollTop  = window.pageYOffset;
        const scrollLeft = window.pageXOffset;

        window.onscroll = function() {
            window.scrollTo(scrollLeft, scrollTop);
        };
    };

    const ShowIcon = () => {
        return (
            <span
                className="material-icons hamburger"
                onClick={() => {setMobileMenu(true); stopScroll()}}
            >
                menu
            </span>
        );
    }

    const getClassName = (btn) => {
        if (!btn?.hasFocused) {
            return btn?.id === 4 ? "preventivi" : "btn";
        } else {
            return btn?.id === 4 ? "preventivi-focused" : "btn";
        }
    };

    const scrollToSection = (event, btn, index) => {
        if (index !== 4) {
            event.preventDefault();
            window.scrollTo({
                top: document.querySelector(btn?.target)?.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const focusPreventiviBtn = (id) => {
        const updatedLinks = links?.map((link, index) => {
            if (index === id) {
                return { ...link, hasFocused: true };
            } else {
                return { ...link, hasFocused: false };
            }
        });
        setLinks(updatedLinks);
    };

    const handleOnClick = (event, btn, index) => {
        if (index !== 2) {
            focusPreventiviBtn(index);
            scrollToSection(event, btn, index);
        } else {

            const scrollTop  = window.pageYOffset;
            const scrollLeft = window.pageXOffset;

            window.onscroll = function() {
                window.scrollTo(scrollLeft, scrollTop);
            };

            setShowModal((prev) => ({ ...prev, show: true, type: "lavora_con_noi" }));
        }
    };

    return (
        <>
            {mobileMenu && (
                <HamburgerMenu
                    setMobileMenu={setMobileMenu}
                    setShowModal={setShowModal}
                    isUserOnPreventivi={isUserOnPreventivi}
                />
            )}

            <div className="header">

                <Logo header={true} links={links} setLinks={setLinks} />

                <div className="links-container">
                    {links?.map((link, index) => (
                        <div key={link?.id} onClick={(e) => handleOnClick(e, link, index)}>
                            <Link
                                to={index === 4 ? '/preventivi' : '/'}
                                className={getClassName(link)}
                            >
                                {link?.section}
                            </Link>
                        </div>
                    ))}
                </div>

                <ShowIcon/>

            </div>
        </>
    );
}

export default Header;