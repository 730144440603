import React, { useEffect, useState } from 'react';
import './impiantiElettrici.css';
import EmailForm from '../../emailForm/EmailForm';

const ImpiantiElettrici = (props) => {

    /* ------ props ------ */
    const {
        step,
        setStep,
        showEmailForm,
        setShowEmailForm,
        preventiviMap,
        setPreventiviMap,
        setShowModal,
        links,
        setLinks
    } = props;

    /* ------ useState() ------ */
    const [showStepTwo  , setShowStepTwo]   = useState(true) ;
    const [showStepThree, setShowStepThree] = useState(false);
    const [showStepFour , setShowStepFour]  = useState(false);

    /* ------ useeffect() ------ */
    useEffect(() => {
        scrollToThisStep();
    }, []);

    const scrollToThisStep = () => {
        window.scrollTo({
            top: document.querySelector("#impianti-page")?.offsetTop,
            behavior: 'smooth'
        });
    };

    const getBackToPrevStep = (stepNum) => {
        switch (stepNum) {
            case 2:
                const restartingPreventivi = preventiviMap?.map(servizio => ({ ...servizio, selected: false }));
                setPreventiviMap(restartingPreventivi);
                setShowStepTwo(false);
                window.scrollTo({
                    top: document.querySelector("#iconsChoice")?.offsetTop,
                    behavior: 'smooth'
                });
                break;
            case 3:
                window.scrollTo({
                    top: document.querySelector("#ie-step2")?.offsetTop,
                    behavior: 'smooth'
                });
                setShowStepThree(false);
                break;
            case 4:
                setShowStepFour(false);
                break;
            default:
                break;
        }
        setStep(step - 1);
    };

    const goToStepThree = (option) => {
        const updatedPreventivi = preventiviMap?.map(servizio => {
            if (servizio?.selected) {
                return {
                    ...servizio,
                    choice: {
                        guasto: { selected: option === 0 ? true : false },
                        nuovaInstallazione: {
                            ...servizio.choice.nuovaInstallazione,
                            selected: option === 1 ? true : false
                        }
                    }
                };
            } else {
                return servizio;
            }
        });

        setPreventiviMap(updatedPreventivi);
        if (option === 0) {
            setStep(step + 1);
            setShowEmailForm(true);
        } else {
            setStep(step + 1);
            setShowStepThree(true);
        }
    };

    const goToStepFour  = (option) => {
        const updatedPreventivi = preventiviMap?.map(servizio => {
            if (servizio?.selected) {
                return {
                    ...servizio,
                    choice: {
                        ...servizio?.choice,
                        nuovaInstallazione: {
                            ...servizio?.choice?.nuovaInstallazione,
                            choice: {
                                civile: {
                                    ...servizio.choice.nuovaInstallazione.choice.civile,
                                    selected: option === 0 ? true : false
                                },
                                industriale : {
                                    ...servizio.choice.nuovaInstallazione.choice.industriale,
                                    selected: option === 1 ? true : false
                                }
                            } 
                        }
                    }
                };
            } else {
                return servizio;
            }
        });
        setPreventiviMap(updatedPreventivi);
        setShowStepFour(true);
        setStep(step + 1);
    };

    const goToStepFive  = (option) => {
        const updatedPreventivi = preventiviMap?.map(service => {
            if (service?.selected) {
                return {
                    ...service,
                    choice: {
                        ...service?.choice,
                        nuovaInstallazione: {
                            ...service?.choice?.nuovaInstallazione,
                            choice : {
                                civile: {
                                    ...service?.choice?.nuovaInstallazione?.choice?.civile,
                                    choice: {
                                        withProject:   service?.choice?.nuovaInstallazione?.choice?.civile?.selected && option === 0 ? true : false,
                                        withNoProject: service?.choice?.nuovaInstallazione?.choice?.civile?.selected && option === 1 ? true : false
                                    }
                                },
                                industriale: {
                                    ...service?.choice?.nuovaInstallazione?.choice?.industriale,
                                    choice: {
                                        withProject:   service?.choice?.nuovaInstallazione?.choice?.industriale?.selected && option === 0 ? true : false,
                                        withNoProject: service?.choice?.nuovaInstallazione?.choice?.industriale?.selected && option === 1 ? true : false
                                    }
                                }
                            }
                        }
                    }
                };
            } else {
                return service;
            }
        });
        setPreventiviMap(updatedPreventivi);
        setStep(step + 1);
        setShowEmailForm(true);
    };

    // ----- Steps della Serpentina ----- //
    const StepTwo = () => {

        const buttons = ["guasto", "nuova installazione"];

        return (
            <div className='imp_step_two' id='ie-step2'>
                <div className='imp_msg-container'>
                    <div className='didascalia'>
                        <h1>2.</h1>
                        <h3>Impianti Elettrici</h3>
                    </div>
                    {step === 2 && (
                        <button className='getBackCircle1' onClick={() => getBackToPrevStep(2)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div className='imp_btns-container'>
                    {buttons?.map((option, index) => (
                        <button
                            className={step > 2 ? 'disabled-btn_even' : 'enabled-btn_even'}
                            disabled={step > 2} onClick={() => goToStepThree(index)}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
        );
    };
    const StepThree = () => {

        useEffect(() => {
            window.scrollTo({
                top: document.querySelector("#imp_step-three")?.offsetTop,
                behavior: 'smooth'
            });
        }, []);

        const buttons = ["civile", "industriale"];

        return (
            <div id='imp_step-three'>
                <div className='imp_msg-container-three'>
                    <div className='didascalia'>
                        <h1>3.</h1>
                        <h3>Impianti Elettrici</h3>
                    </div>
                    {step === 3 && (
                        <button className='getBackCircle2' onClick={() => getBackToPrevStep(3)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div className='imp_btns-container-three'>
                    {buttons?.map((option, index) => (
                        <button
                            className={step > 3 ? 'disabled-btn_odd' : 'enabled-btn_odd'}
                            onClick={() => goToStepFour(index)} disabled={step > 3}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
        );
    };
    const StepFour = () => {

        useEffect(() => {
            window.scrollTo({
                top: document.querySelector("#imp_step-four")?.offsetTop,
                behavior: 'smooth'
            });
        }, []);

        const buttons = ["ho un progetto", "non ho un progetto"];

        return (
            <div id='imp_step-four'>
                <div className='imp_msg-container'>
                    <div className='didascalia'>
                        <h1>4.</h1>
                        <h3>Impianti Elettrici</h3>
                    </div>
                    {step === 4 && (
                        <button className='getBackCircle1' onClick={() => getBackToPrevStep(4)} >
                            torna indietro
                        </button>
                    )}
                </div>
                <div className='imp_btns-container'>
                    {buttons?.map((option, index) => (
                        <button
                            className={step > 4 ? 'disabled-btn_even' : 'enabled-btn_even'}
                            onClick={() => goToStepFive(index)} disabled={step > 4}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div id='impianti-page'>
            {showStepTwo   && <StepTwo   />}
            {showStepThree && <StepThree />}
            {showStepFour  && <StepFour  />}

            {showEmailForm && (
                <EmailForm
                    step={step} setStep={setStep}
                    preventiviMap={preventiviMap}
                    setShowEmailForm={setShowEmailForm}
                    setShowModal={setShowModal}
                    links={links} setLinks={setLinks}
                />
            )}
        </div>
    );
}

export default ImpiantiElettrici;