import React from 'react';
import './footer.css';
import Contatti from '../contatti/Contatti';
import Social from '../social/Social';
import ArrowUpward from '../arrowUpward/ArrowUpward.js';
import Logo from '../logo/Logo';
import PrivacyCookiesPolicy from '../privacyPolicy/PrivacyCookiesPolicy';

const Footer = ({ links, setLinks }) => {

    return (
        <div id='footer'>

            <div className='footer_2'>

                <Logo header={false} links={links} setLinks={setLinks}/>
                <Contatti />
                <Social   />

                <div className='scrollUp'>
                    <ArrowUpward white={true} links={links} setLinks={setLinks}/>
                </div>
            </div>

            <PrivacyCookiesPolicy/>
            
        </div>
    );
}

export default Footer;