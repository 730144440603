import React from 'react';
import './hamburgerMenu.css';
import { Link } from 'react-router-dom';

const HamburgerMenu = (props) => {

    /* --- props --- */
    const {
        setMobileMenu,
        setShowModal,
        isUserOnPreventivi
    } = props;

    const handleOnTouch = (event, route) => {

        window.onscroll = function () {};

        switch (route) {
            case "lavora con noi":

                const scrollTop  = window.pageYOffset;
                const scrollLeft = window.pageXOffset;

                window.onscroll = function() {
                    window.scrollTo(scrollLeft, scrollTop);
                };

                setShowModal((prev) => ({ ...prev, show: true, type: "lavora_con_noi" }));
                break;
            case "chi siamo":

                event.preventDefault();
                window.scrollTo({
                    top: document.querySelector("#chisiamo")?.offsetTop,
                    behavior: 'smooth'
                });
                break;
            case "contatti":

                event.preventDefault();
                window.scrollTo({
                    top: document.querySelector("#footer")?.offsetTop,
                    behavior: 'smooth'
                });
            break;
            default:
                return null;
        }
        setMobileMenu(false);
    };

    const menu = () => {

        const routes = isUserOnPreventivi ? ["home", "lavora con noi", "contatti", "preventivi"] : ["home", "chi siamo", "lavora con noi", "contatti", "preventivi"];

        return routes?.map((route, index) => (
            <li key={index} onClick={() => setMobileMenu(false)}>
                {(route === "home" || route === "preventivi") ? (
                    <Link to={route === "preventivi" ? "/preventivi" : "/"}>
                        <p>{route}</p>
                    </Link>
                ) : (
                    <button onClick={(e) => handleOnTouch(e, route)}>
                        {route}
                    </button>
                )}
            </li>
        ));
    };

    return (
        <div 
            className="menu"
            onClick={() => {
                setMobileMenu(false);
                window.onscroll = function () {};
            }}
        >
            <ul>
                {menu()}
            </ul>
        </div>
    );
}

export default HamburgerMenu;
