import React, { useState } from 'react';
import './servizi.css';
import ArrowUpward from '../../arrowUpward/ArrowUpward';
import data from '../../../utils.js/data.json';
import impiantistica from '../../../utils.js/imgs/services/elettrica.jpg';
import fotovoltaico from '../../../utils.js/imgs/services/fotovoltaico.jpg';
import termoidraulica from '../../../utils.js/imgs/services/termoidraulica.jpg';
import antintrusione from '../../../utils.js/imgs/services/antintrusione.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlugCircleBolt, faSolarPanel, faFan, faDoorOpen } from '@fortawesome/free-solid-svg-icons';

const Servizi = (props) => {

    /* ---- props ---- */
    const {
        links,
        setLinks,
        setShowModal
    } = props;

    /* ------ useState() ------ */
    const [photos] = useState([impiantistica, fotovoltaico, termoidraulica, antintrusione]);
    const [icons]  = useState([faPlugCircleBolt, faSolarPanel, faFan, faDoorOpen]);

    const getClassName = (index) => {
        const leftOrRight = (index % 2 === 0) ? "right" : "left";
        return `service-section-${leftOrRight}`;
    };

    const getStyle = (index) => {
        return {
            backgroundImage    : `url(${photos[index]})`,
            backgroundRepeat   : "no-repeat"            ,
            backgroundPosition : "center"               ,
            backgroundSize     : "cover"                ,
            filter             : "grayscale(80%)"
        }
    };

    const showDescriptionModal = (index) => {

        const scrollTop  = window.pageYOffset;
        const scrollLeft = window.pageXOffset;

        window.onscroll = function() {
            window.scrollTo(scrollLeft, scrollTop);
        };

        setShowModal({ show: true, type: "servizi", id: index});
    };

    return (
        <div id='servizi'>

            <div className='title-section'>
                <h1>SERVIZI</h1>
                <div className='scrollUp-servizi'>
                    <ArrowUpward white={false} links={links} setLinks={setLinks}/>
                </div>
            </div>

            <div className='grid-section'>
                {data?.servizi?.map((servizio, index) => (
                    <div key={servizio?.alt} className={getClassName(index)}>

                        <div className='photo-container' style={getStyle(index)}/>

                        <div className='description-section'>
                            <div>
                                <FontAwesomeIcon icon={icons[index]} />
                            </div>
                            <div>
                                <h3>{servizio?.serviceName}</h3>
                            </div>
                            <div>
                                <p>{servizio?.incompleteContent}</p>
                            </div>
                            <div>
                                <button onClick={() => showDescriptionModal(index)}>
                                    leggi di più
                                </button>
                            </div>
                        </div>

                    </div>
                ))}
            </div>

        </div>
    );
}

export default Servizi;