import React from 'react';
import './contatti.css';
import data from '../../utils.js/data.json';

const Contatti = () => {
    return (
        <div className='contatti-footer'>

            <div className='contatti_titolo'>
                <h1>CONTATTI</h1>
            </div>

            <div className='recapiti'>
                {data?.contatti?.map((recapito, index) => (
                    <>
                        {(index === 0) ? (
                            <div className='recapito' key={recapito.span}>
                                <span className={recapito.class}>{recapito.span}</span>                    
                                <p>{recapito.value}</p>
                            </div>
                        ) : (
                            <div className='recapito' key={recapito.span}>
                                <span className={recapito.class}>{recapito.span}</span>                    
                                <a
                                    href={recapito?.href}
                                    target="_blank"rel="noreferrer"
                                >
                                    {recapito.value}
                                </a>
                            </div>
                        )}
                    </>
                ))}
            </div>
        </div>
    );
}

export default Contatti;

// Da ripristinare con nuovo indirizzo mail, in attesa di conferma
/* {
    "value": "acsistems@gmail.com",
    "class": "material-icons"     ,
    "span": "email"               ,
    "href":"mailto:acsistems@gmail.com"
}, */