import React from 'react';
import './privacyCookiesPolicy.css';

const PrivacyCookiesPolicy = () => {
    return (
        <div className='privacyPolicy'>
            <a
                href="https://www.iubenda.com/privacy-policy/97287222"
                class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                title="Privacy Policy "
            >
                Privacy Policy
            </a>

            <a
                href="https://www.iubenda.com/privacy-policy/97287222/cookie-policy"
                class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                title="Cookie Policy "
            >
                Cookie Policy
            </a>
        </div>
    );
}

export default PrivacyCookiesPolicy;