import React, { Fragment, useEffect, useState } from 'react';
import './chiSiamo.css';
import data from '../../../utils.js/data.json';
import ArrowUpward from '../../arrowUpward/ArrowUpward';
import pic1 from '../../../utils.js/imgs/chiSiamo/chisiamo1.jpg';
import pic3 from '../../../utils.js/imgs/chiSiamo/chisiamo3.jpg';

const ChiSiamo = (props) => {

    /* ---- props ---- */
    const {
        links,
        setLinks
    } = props;

    /* ---- useState() ---- */
    const [pictures] = useState(["", pic1, "", pic3]);
    const [content, setContent] = useState(data?.chiSiamo);

    /* ---- useEffect ---- */
    useEffect(() => {
        const contentWithPics = content?.map((part, index) => {
            if (index === 0) {
                return part;
            } else {
                return { ...part, imgSrc: pictures?.[index] };
            }
        });
        setContent(contentWithPics);
        // eslint-disable-next-line
    }, []);

    return (
        <div id='chisiamo'>

            <div className='title-section-chisiamo'>
                <h1>CHI SIAMO</h1>
                <div className='scrollUp-chisiamo'>
                    <ArrowUpward white={false} links={links} setLinks={setLinks}/>
                </div>
            </div>

            <div className='storia'>
                {content?.map((part, index) => (
                    <Fragment key={`${part}_${index + 1}`}>
                        {(index === 0 || index === 2) ? (
                            <div className={`text${index + 1}`}>
                                {(index === 0) ? (
                                    <p>
                                    " Sono <b>Antonio Cuccu</b> ed ho fondato l’azienda <b>AC</b> nel 2021 come ditta individuale nel campo elettrico. Da allora la crescita è stata rapida e continua. Dalla prima assunzione di un assistente, sino ai 20 dipendenti del 2023. Di pari passo con la crescita delle richieste e del fatturato, si sono ampliati anche i campi operativi con il fotovoltaico e la riqualificazione energetica, il termoidraulico, la videosorveglianza ed i sistemi antintrusione. "
                                    </p>
                                ) : (
                                    <p>
                                        " {part?.text} "
                                    </p>
                                )}
                            </div> 
                        ) : (
                            <>
                                {(index === 1) ? (
                                    <div className={`text2`}>
                                        <div
                                            className="text-container2"
                                        >
                                            <p>
                                                AC Systems oggi è formata da giovani con esperienza
                                            </p>
                                            <p>{part?.text}</p>
                                        </div>
                                        <div
                                            className="pic-container2"
                                        >
                                            <img src={part?.imgSrc} alt="team" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className={`text4`}>
                                        <div className="text-container4">   
                                            <p>
                                                Chi ci sceglie
                                            </p>
                                            <p>{part?.text}</p>
                                        </div>
                                        <div className="pic-container4">
                                            <img src={part?.imgSrc} alt="team" />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </Fragment>
                ))}
            </div>

        </div>
    );
}

export default ChiSiamo;